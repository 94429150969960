import React, { useEffect, useState } from "react";
import clsx from "clsx";

const InputCheckbox = ({
  name,
  placeholder,
  formState,
  hasError,
  checked,
  id,
  value,
  groupState,
  className: _className,
}) => {
  const [form, setForm] = formState;
  const [checkedList, setCheckedList] = groupState || useState(false);

  const updateChecklist = e => {
    const add = e.target.checked;

    if (add) {
      setCheckedList([...checkedList, e.target.value]);
    } else {
      const removeEntry = checkedList?.filter(i => i !== e.target.value);
      setCheckedList(removeEntry);
    }
  };

  useEffect(() => {
    if (groupState) {
      setForm({
        ...form,
        [name]: checkedList,
      });
    }
  }, [checkedList]);

  return (
    <input
      type="checkbox"
      name={name}
      id={id || name}
      value={value}
      placeholder={placeholder}
      className={clsx(_className, {
        "border-red": hasError,
      })}
      onChange={e => {
        if (groupState) {
          updateChecklist(e);
        } else {
          setForm({
            ...form,
            [name]: e.target.checked ? e.target.value : null,
          });
        }
      }}
      checked={checked}
    />
  );
};

export default InputCheckbox;
