import React from "react";
import { Image, Text } from "@atoms";

const CbImages = ({ images, caption }) => {
  if (images?.length > 0) {
    return (
      <div className="my-6 md:my-10">
        <div className="flex flex-wrap gap-4 sm:flex-nowrap">
          {images?.map(image => {
            return <Image image={image} key={image.uid} />;
          })}
        </div>
        {caption && (
          <Text variant="xs" className="mt-2 text-peach">
            {caption}
          </Text>
        )}
      </div>
    );
  }
  return null;
};

export default CbImages;
