import React from "react";
import { Text } from "@atoms";
import { motion, useReducedMotion } from "framer-motion";

const ScrollingHeading = ({ heading }) => {
  const shouldReduceMotion = useReducedMotion();
  return (
    <motion.div
      className="relative z-20 -mb-10 w-max space-x-4 md:-mb-14"
      animate={!shouldReduceMotion ? { x: ["-25%", "-50%"] } : null}
      transition={{ duration: 10, ease: "linear", repeat: Infinity }}
    >
      <Text
        variant="massiveHeader"
        className="text-b-pink-to-purple whitespace-nowrap uppercase"
      >
        {heading}
      </Text>
      <span aria-hidden="true">
        <Text
          variant="massiveHeader"
          className="text-b-pink-to-purple whitespace-nowrap uppercase"
        >
          {heading}
        </Text>
      </span>
      <span aria-hidden="true">
        <Text
          variant="massiveHeader"
          className="text-b-pink-to-purple whitespace-nowrap uppercase"
        >
          {heading}
        </Text>
      </span>
      <span aria-hidden="true">
        <Text
          variant="massiveHeader"
          className="text-b-pink-to-purple whitespace-nowrap uppercase"
        >
          {heading}
        </Text>
      </span>
    </motion.div>
  );
};

export default ScrollingHeading;
