import React from "react";
import clsx from "clsx";

const InputText = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  options,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <select
      name={name}
      id={name}
      className={clsx(
        "border-grey w-full max-w-full border p-0.5 py-1",
        _className,
        {
          "!border-red": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
      required={required}
    >
      {placeholder && (
        <option key="placeholder" value="">
          {placeholder}
        </option>
      )}
      {options?.map(option => {
        const { text, value } = option || {};
        return (
          // eslint-disable-next-line react/no-array-index-key
          <option key={value} value={value} id={value}>
            {text}
          </option>
        );
      })}
    </select>
  );
};

export default InputText;
