import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { motion, useAnimation } from "framer-motion";
import Icon from "./Icon";
import Text from "./Text";

const Dropdown = ({
  model,
  options,
  placeholder = "Select One",
  inverse,
  size = "md",
  sans,
  id = "dropdown",
  label,
}) => {
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = model || useState();
  const selectedValue = options.filter(o => Object.keys(o)?.[0] === value)?.[0];
  const dropdown = useRef();
  const dropdownControl = useAnimation();

  // close menu if it's open and you click outside of it
  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  // change state and close menu when a dropdown item is selected
  useEffect(() => {
    setIsActive(false);
  }, [value]);

  // animations
  useEffect(() => {
    if (isActive) {
      dropdownControl.start({
        height: "auto",
        opacity: 1,
        display: "block",
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
      });
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }
    if (!isActive) {
      dropdownControl.start({
        height: 0,
        opacity: 0,
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
        transitionEnd: {
          display: "hidden",
        },
      });
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
  }, [isActive]);

  // eslint-disable-next-line global-require

  let textSize;
  let iconSize;

  switch (size) {
    case "xs":
      textSize = "text-xs";
      iconSize = "w-1 h-1";
      break;
    case "sm":
      textSize = "text-sm";
      iconSize = "w-2 h-2";
      break;
    case "md":
      textSize = "text-md";
      iconSize = "w-3 h-3";
      break;
    case "lg":
      textSize = "text-lg";
      iconSize = "w-4 h-4";
      break;
    default:
      textSize = "text-md";
      iconSize = "w-3 h-3";
      break;
  }

  return (
    <div
      className={clsx("relative", {
        "font-sans text-xs font-bold uppercase": sans,
        "font-serif": !sans,
      })}
    >
      <motion.button
        type="button"
        ref={dropdown}
        onTap={toggleActive}
        className={clsx(
          "flex w-full items-center justify-between transition duration-200",
          {
            "px-3 py-1": size === "xs",
            "px-4 py-2": size !== "xs",
            "bg-black text-white": inverse,
            "text-text-color": !inverse,
            "bg-transparent": !isActive,
            "rounded-t-md bg-white shadow-xl": isActive,
          },
          textSize
        )}
        aria-expanded={isActive}
        aria-controls={id}
        aria-haspopup="listbox"
      >
        <span className="mr-2 whitespace-nowrap text-left">
          <Text variant="body">
            {value != null && value !== ""
              ? Object.values(selectedValue)[0]
              : placeholder}
          </Text>
        </span>
        {/* dropdown Icon */}
        <Icon
          name="chevron"
          className={clsx(
            "icon transform text-highlight duration-200",
            {
              "rotate-90": isActive,
              "rotate-0": !isActive,
            },
            iconSize
          )}
        />
      </motion.button>
      <div className="relative -mt-px">
        <motion.ul
          animate={dropdownControl}
          className={clsx(
            "absolute left-0 right-0 z-40 h-0 overflow-hidden rounded-b-md",
            {
              "bg-black text-white": inverse,
              "bg-white text-black": !inverse,
              "shadow-xl": isActive,
              "shadown-none": !isActive,
            },
            textSize
          )}
          id={id}
          role="listbox"
          aria-label={label || placeholder || `dropdown--${id}`}
          tabindex="-1"
        >
          {placeholder.length > 1 && (
            <motion.li
              role="option"
              aria-selected={false}
              className={clsx(
                "w-full cursor-pointer px-4 py-2 text-left opacity-50",
                textSize
              )}
              disabled
            >
              <Text variant="body">{placeholder}</Text>
            </motion.li>
          )}
          {options?.map(option => {
            const [k, v] = Object.entries(option)[0];
            return (
              <motion.li
                role="option"
                aria-selected={value === k}
                key={option.uid ? option.uid : k}
                onTap={() => setValue(k)}
                className="w-full cursor-pointer px-4 py-2 text-left transition duration-200 hover:bg-highlight hover:text-white focus:bg-highlight"
                tabindex={isActive ? "0" : "-1"}
              >
                <Text variant="body">{v}</Text>
              </motion.li>
            );
          })}
        </motion.ul>
      </div>
    </div>
  );
};

export default Dropdown;
