import resolveImage from "./resolveImage";
import resolveContentBuilder from "./resolveContentBuilder";

const resolveArticle = article => {
  const postDate = new Date(Date.parse(article?.postDate)).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );

  return {
    ...article,
    image: resolveImage(article?.image0),
    author: article?.articleAuthor?.[0]?.title || "STEM From Dance",
    postDate,
    blocks: resolveContentBuilder(article?.contentBuilder),
    hero: {
      heading: article?.title,
      image: resolveImage(article?.image0),
      author: article?.articleAuthor?.[0]?.title || "STEM From Dance",
      postDate,
      categories: article?.articleTopic?.map(c => c.title).join(", "),
    },
    description: article?.descriptor0,
    meta: {
      url: article?.url,
      slug: article?.slug,
      title: article?.title,
      metaPageTitle: article?.metaTitle,
      metaDescription: article?.metaDescription,
      metaImage: resolveImage(article?.metaImage),
    },
    share: { url: article?.url },
  };
};

export default resolveArticle;
