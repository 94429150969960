import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query ErrorQuery {
    craft {
      globalSet(handle: "error404") {
        ... on Craft_error404_GlobalSet {
          heading0
          descriptor0
          link0 {
            text
            url
          }
          link1 {
            text
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { heading0, descriptor0, link0, link1 } = craft.globalSet;

  return {
    header: heading0,
    description: descriptor0,
    primary: link0,
    secondary: link1,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
