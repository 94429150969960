import React from "react";
import clsx from "clsx";
import Text from "./Text";

const CbWrapper = ({
  indent: _indent,
  count,
  children,
  margin = "default",
  type,
}) => {
  let indent = _indent;
  if (count) {
    indent = true;
  }
  const marginValues = {
    removeTop: "mb-10 -mt-6",
    topOnly: "mt-10",
    bottomOnly: "mb-10",
    default: "my-10",
  };
  return (
    <div
      className={clsx(
        "relative",
        {
          "pl-6": indent,
          [`content-builder__${type}`]: true,
        },
        marginValues[margin]
      )}
    >
      {count && (
        <div className="font-serif absolute left-0 top-0 w-4 font-bold text-red">
          <Text variant="body--large-tight">{`${count}.`} </Text>
        </div>
      )}
      {children}
    </div>
  );
};

export default CbWrapper;
