import React from "react";
import { Text, Icon, Image, ButtonAlt } from "@atoms";

const CbQuote = ({ quote, author, title, headshot, link }) => {
  return (
    <div className="my-6 md:my-10">
      <div className="space-y-4 bg-gradient-to-t from-pink to-orange-gradient p-8">
        <figure>
          <blockquote className="mb-6 flex flex-row items-start pr-0 md:pr-6">
            <span className="-mt-2 mr-2 shrink-0" aria-hidden="true">
              <Icon name="quote" className="w-8 text-lavender" />
            </span>
            <Text variant="lg" className="text-left text-white" dewidow>
              {quote}
            </Text>
          </blockquote>
          <figcaption className="flex items-center gap-4">
            {headshot && (
              <div className="relative h-10 w-10 overflow-hidden rounded-full sm:h-16 sm:w-16">
                <Image
                  image={headshot}
                  fill
                  ixParams={{ fit: "facearea", facepad: 9 }}
                />
              </div>
            )}
            <div>
              <Text variant="body" className="text-left text-white">
                {author}
              </Text>
              <Text variant="sm" className="mt-1 text-left text-lavender">
                {title}
              </Text>
            </div>
          </figcaption>
        </figure>
        {link?.url && (
          <ButtonAlt
            to={link?.url}
            className="!decoration-white hover:!decoration-orange"
          >
            <Text variant="body" className="text-white">
              {link?.text}
            </Text>
          </ButtonAlt>
        )}
      </div>
    </div>
  );
};

export default CbQuote;
