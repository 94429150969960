import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";

const Sticky = ({ children, className, top = 0, bottom = 0 }) => {
  // setup refs
  const el = useRef();
  const elContainer = useRef();
  const height = useRef();
  // Set up state
  const [stick, setStick] = useState(false);
  const [y, setY] = useState();
  const [direction, setDirection] = useState("top");
  // Set up un-stuck positions early
  const resting = direction === "bottom" ? (height.current || 0) - bottom : 0;

  useEffect(() => {
    let elObserver;

    if (elContainer.current && el.current) {
      // Get initial height so it isn't calculated on every scroll
      height.current =
        elContainer.current.clientHeight - el.current.clientHeight - bottom;
    }

    if (el.current) {
      // create resize observer
      elObserver = new ResizeObserver(entries => {
        entries.forEach(e => {
          // Update the targets within the el changes
          elContainer.current = e?.target?.parentElement?.parentElement;
          el.current = e?.target;
          // Update the height as well, so it doesn't keep the
          // initial dimensions
          height.current =
            elContainer.current.clientHeight - el.current.clientHeight - bottom;

          if (
            direction === "bottom" &&
            el.current.clientHeight < elContainer.current.clientHeight
          ) {
            setDirection("top");
            setY(0);
          }
        });
      });
      // observe children
      Array.from(el.current.children).forEach(child => {
        elObserver.observe(child);
      });
    }
    return () => {
      // remove observer
      if (elObserver) {
        elObserver.disconnect();
      }
    };
  }, []);

  const scroll = () => {
    if (elContainer.current && el.current) {
      // Silently listen to scroll event if it isn't
      // actively changinging anything
      if (el.current.clientHeight < elContainer.current.clientHeight) {
        const hitTop = elContainer.current.getBoundingClientRect().top <= top;
        const hitBottom =
          elContainer.current.getBoundingClientRect().top <
          (height.current - top - bottom) * -1;
        const position =
          elContainer.current.getBoundingClientRect().top * -1 + top;

        setStick(hitTop && !hitBottom);

        // Request the next frame so the scrolling animation
        // doesn't come across as super janky
        requestAnimationFrame(() => {
          setY(position);
        });
      } else {
        setY(0);
      }
    }
  };

  useEffect(() => {
    setDirection(prevDirection => {
      // Check the positions to get the correct position
      // to leave the sticky element
      if (y <= 0) {
        return "top";
      }
      if (y >= height.current - top - bottom) {
        return "bottom";
      }
      return prevDirection;
    });
  }, [y]);

  useEffect(() => {
    window.addEventListener("scroll", scroll);

    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  return (
    // the container
    <motion.div ref={elContainer} className="relative h-full w-full">
      {/* the sticky el itself */}
      <motion.div
        ref={el}
        className={clsx(className, "will-change-transform")}
        style={{ y: stick ? y : resting }}
        transition={{ ease: "linear" }}
      >
        {children}
      </motion.div>
      {/* end trigger at bottom of container */}
    </motion.div>
  );
};

export default Sticky;
