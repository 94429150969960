import React, { useState } from "react";
import clsx from "clsx";
import {
  InputText,
  InputTextarea,
  InputCheckbox,
  InputRadio,
  InputSelect,
} from "@base";
import Text from "./Text";

const Input = ({
  label,
  hideLabel,
  type,
  placeholder,
  name,
  singleLine,
  formState,
  errorState,
  errorMessage,
  className: _className,
  value,
  required,
  options,
  id,
  group,
  bigHeader,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error] = errorState || useState([]);
  const hasError = error?.includes(name);
  const [checkedList, setCheckedList] = useState([]);

  let Field;
  switch (type) {
    case "text":
      Field = InputText;
      break;
    case "textarea":
      Field = InputTextarea;
      break;
    case "checkbox":
      Field = InputCheckbox;
      break;
    case "radio":
      Field = InputRadio;
      break;
    case "select":
      Field = InputSelect;
      break;
    case "hidden":
      Field = InputText;
      break;
    default:
      Field = InputText;
      break;
  }

  // Only have the group work with checkboxes and radio buttons
  // If wanting to have multiple <Input/>s, use a <FieldSet /> as a container:
  // <FieldSet>
  //   <Input>
  //   <Input>
  //   <Input>
  // </FieldSet>
  if (group?.length && (type === "checkbox" || type === "radio")) {
    return (
      <fieldset className={clsx(_className, { "text-red": hasError })}>
        {label && (
          <legend
            className={clsx("flex w-full justify-center text-white", {
              "sr-only": hideLabel,
              "mb-6": bigHeader,
              "mb-2": !bigHeader,
            })}
          >
            <span className="flex">
              <Text>
                {label} {required && <span className="text-red">*</span>}
              </Text>
            </span>
          </legend>
        )}
        {group?.map((_g, i) => {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${id}-${name}--${i}`}
              className={clsx({
                "mb-2 flex items-center": singleLine || _g?.singleLine,
                "mb-2 flex flex-row-reverse items-center justify-end":
                  type === "checkbox" || type === "radio",
              })}
            >
              <label
                htmlFor={_g?.id || name}
                className={clsx("block text-sm text-white", {
                  "mb-2":
                    !singleLine && type !== "checkbox" && type !== "radio",
                  "ml-2": type === "checkbox" || type === "radio",
                  "mr-2": singleLine || _g?.singleLine,
                })}
              >
                {_g?.label}
              </label>
              <Field
                {..._g}
                id={_g?.id || name}
                name={_g?.name || name}
                value={_g.value}
                formState={[form, setForm]}
                hasError={hasError}
                groupState={
                  type === "checkbox" ? [checkedList, setCheckedList] : null
                }
                className=""
                hidden={type === "hidden"}
                required={required}
              />
            </div>
          );
        })}
        {errorMessage && hasError && (
          <Text variant="xs" className="mt-1 text-red">
            {errorMessage}
          </Text>
        )}
      </fieldset>
    );
  }

  return (
    <div className={clsx(_className)}>
      <div
        className={clsx("relative", {
          "flex items-center": singleLine,
          "flex flex-row-reverse items-center justify-end":
            type === "checkbox" || type === "radio",
          "text-red": hasError,
          hidden: type === "hidden",
        })}
      >
        <label
          htmlFor={id || name}
          className={clsx("block text-xs uppercase text-white", {
            "mb-2": !singleLine && type !== "checkbox" && type !== "radio",
            "mr-2": singleLine,
            "ml-2": type === "checkbox" || type === "radio",
            "sr-only": hideLabel,
          })}
        >
          <span className="flex">
            <Text>
              {label} {required && <span className="text-red">*</span>}
            </Text>
          </span>
        </label>

        <Field
          name={name}
          id={id || name}
          value={value}
          placeholder={placeholder}
          formState={[form, setForm]}
          hasError={hasError}
          options={options}
          className=""
          hidden={type === "hidden"}
          required={required}
        />
      </div>
      {errorMessage && hasError && (
        <Text variant="xs" className="mt-1 text-red">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default Input;
