import React, { useState } from "react";
import clsx from "clsx";
import usePagination from "@hooks/usePagination";
import { Icon } from "@atoms";

const Pagination = ({
  data,
  pageSize = 9,
  rangeLimit = 1,
  showNavigation = true,
  component: Component,
  dark,
}) => {
  const pages = Math.ceil(data.length / pageSize) || 0;
  const [currentPage, setCurrentPage] = useState(1);

  const pagify = (array, chunkSize) => {
    const chunk = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunk.push(array.slice(i, i + chunkSize));
    }
    return chunk;
  };

  const scrollToTop = () =>
    document.getElementById("pagination-list").scrollIntoView();

  if (pages) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const paginationRange = usePagination({
      pages,
      currentPage,
      rangeLimit,
    });

    const nextPage = () => {
      scrollToTop();
      setCurrentPage(page => (page < pages ? page + 1 : page));
    };
    const prevPage = () => {
      scrollToTop();
      setCurrentPage(page => (page > 1 ? page - 1 : 1));
    };
    // use the number within the button to set the page
    const changePage = event => {
      scrollToTop();
      const pageNumber = Number(event.target.textContent);
      setCurrentPage(pageNumber);
    };
    // chunk the data into pages
    const paginatedData = pagify(data, pageSize);

    return (
      <>
        <div
          className="flex scroll-my-8 flex-col gap-10 md:gap-16"
          id="pagination-list"
        >
          {paginatedData?.[currentPage - 1]?.map((item, i, array) => (
            <Component
              horizontal
              isDark={item?.month}
              hideLinkText={!item?.month}
              key={item?.uid}
              showHeading={item?.month !== array[i - 1]?.month}
              {...item}
            />
          ))}
        </div>
        {pages > 1 && (
          <div className="mt-8 flex flex-wrap gap-2 sm:mt-16">
            {/* previous button */}
            {showNavigation && (
              <button
                key="previous"
                onClick={prevPage}
                type="button"
                className={clsx(
                  "leading-tighter h-8 w-8 rounded-full border p-2 text-sm duration-300",
                  {
                    "pointer-events-auto cursor-default opacity-20":
                      currentPage === 1,
                    "border-highlight": !dark,
                    "border-text-color": dark,
                    "group hover:bg-highlight hover:text-white focus:ring-2 focus:ring-highlight":
                      currentPage !== 1 && !dark,
                    "group  hover:border-orange hover:bg-orange hover:text-white focus:ring-2 focus:ring-orange":
                      currentPage !== 1 && dark,
                  }
                )}
              >
                <Icon
                  name="chevron"
                  className={clsx(
                    "h-4 w-2.5 rotate-90 group-hover:text-white",
                    { "text-highlight": !dark },
                    { "text-text-color": dark }
                  )}
                />
                <span className="sr-only">Previous page</span>
              </button>
            )}
            {/* page number buttons */}
            {paginationRange.map(pageNumber => {
              if (pageNumber === "...") {
                return (
                  <span
                    key="elipsis"
                    className={clsx("leading-tighter p-2", {
                      "text-highlight": !dark,
                      "text-text-color": dark,
                    })}
                  >
                    {pageNumber}
                  </span>
                );
              }
              return (
                // using vanilla button here to allow for active button styling
                <button
                  key={pageNumber}
                  type="button"
                  className={clsx(
                    "leading-tighter h-8 w-8 rounded-full border p-2 text-sm duration-300 focus:ring-2",
                    {
                      "border-highlight text-highlight hover:border-highlight hover:bg-highlight hover:text-white focus:ring-highlight":
                        currentPage !== pageNumber && !dark,
                      "border-text-color text-text-color hover:border-orange hover:bg-orange hover:text-white focus:ring-orange":
                        currentPage !== pageNumber && dark,
                      "border-highlight bg-highlight text-white focus:ring-highlight":
                        currentPage === pageNumber && !dark,
                      "border-orange bg-orange text-white focus:ring-orange":
                        currentPage === pageNumber && dark,
                    }
                  )}
                  onClick={changePage}
                >
                  <span>{pageNumber}</span>
                </button>
              );
            })}
            {/* next button */}
            {showNavigation && (
              <button
                onClick={nextPage}
                type="button"
                className={clsx(
                  "leading-tighter h-8 w-8 rounded-full border p-2 text-sm duration-300",
                  {
                    "pointer-events-auto cursor-default opacity-20":
                      currentPage === pages,
                    "border-highlight": !dark,
                    "border-text-color": dark,
                    "group hover:bg-highlight hover:text-white focus:ring-2 focus:ring-highlight":
                      currentPage !== pages && !dark,
                    "group hover:border-orange hover:bg-orange hover:text-white focus:ring-2 focus:ring-orange":
                      currentPage !== pages && dark,
                  }
                )}
              >
                <Icon
                  name="chevron"
                  className={clsx(
                    "h-4 w-2.5 -rotate-90 group-hover:text-white",
                    { "text-highlight": !dark },
                    { "text-text-color": dark }
                  )}
                />
                <span className="sr-only">Next page</span>
              </button>
            )}
          </div>
        )}
      </>
    );
  }
  return null;
};

export default Pagination;
