import React, { useState } from "react";
import clsx from "clsx";
import { Button, Text } from "@atoms";

const FormContainer = ({
  children,
  onSubmit,
  buttonText = "Submit",
  className: _className,
  successMessage = "The form has been submitted.",
  errorMessage = "Please fill out all required fields",
  formState,
  buttonColor,
  buttonAlign = "center",
  action,
  method,
  id,
}) => {
  // Form States
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formError, setFormError] = useState([]);

  const requiredFields = React.Children.toArray(children)
    ?.map(_f => _f.props)
    ?.map(_f => {
      if (Array.isArray(_f?.children)) {
        return _f?.children?.map(_c => _c?.props);
      }

      return _f;
    })
    ?.flat()
    ?.filter(_f => _f?.required)
    ?.map(_f => _f?.name)
    ?.sort();

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        formState: [form, setForm],
        errorState: [formError, setFormError],
      });
    }
    return child;
  });

  const handleSubmit = async e => {
    e.preventDefault();

    const formKeys = Object.keys(form)
      ?.filter(_f => {
        const isEmpty =
          form[_f]?.length === 0 || form[_f] === false || form[_f] === null;
        return !isEmpty ? _f : false;
      })
      ?.sort();
    const hasRequiredFields =
      formKeys?.length > 0 &&
      requiredFields.every(value => {
        return formKeys?.includes(value);
      });

    setFormError([]);

    if (hasRequiredFields && !success) {
      if (onSubmit) {
        try {
          const res = await onSubmit(e);

          if (!res.error) {
            setError(null);
            setSuccess(successMessage);
          } else {
            setSuccess(null);
            setError(
              res.error ||
                "Oops, Something happened with the form submission. Try again later."
            );
          }
        } catch (_e) {
          setError(errorMessage);
        }
      }
    } else {
      const missingFields = requiredFields?.filter(x => !formKeys.includes(x));
      // ?.concat(formKeys.filter(x => !requiredFields.includes(x)));

      setFormError(missingFields);
      setError(errorMessage);
    }
  };

  return (
    <form
      id={id}
      onSubmit={handleSubmit}
      className={clsx(_className)}
      method={method}
      action={action}
    >
      {error && (
        <Text variant="body" className="text-center text-white">
          {error}
        </Text>
      )}
      {success && (
        <Text variant="body" className="text-center text-white">
          {success}
        </Text>
      )}
      {!success && (
        <>
          {childrenWithProps}
          {/*
            the dom parsing borks the hydration, so having a span
            inbetween keeps it from transfering its classes
           */}
          <span>
            <div
              className={clsx("mt-4 flex", {
                "justify-center": buttonAlign === "center",
                "justify-start": buttonAlign === "left",
                "justify-end": buttonAlign === "right",
              })}
            >
              <Button color={buttonColor} type="submit">
                {buttonText}
              </Button>
            </div>
          </span>
        </>
      )}
    </form>
  );
};

export default FormContainer;
