import React from "react";
import clsx from "clsx";
import { Text, Button, ButtonAlt } from "@atoms";

const CbButton = ({ link, style }) => {
  const Tag = style === "link" ? ButtonAlt : Button;
  return (
    <div className="my-6 md:my-10">
      {link?.url && (
        <Tag to={link?.url} color="pink">
          <Text
            variant="body"
            className={clsx({
              "text-text-color": style === "link",
              "text-white": style !== "link",
            })}
          >
            {link.text}
          </Text>
        </Tag>
      )}
    </div>
  );
};

export default CbButton;
