import { useStaticQuery, graphql } from "gatsby";
import { resolveArticle } from "@dataResolvers";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query RecentArticlesQuery {
    craft {
      articles: entries(
        section: "articles"
        limit: 3
        relatedTo: [28615, 23911]
      ) {
        ... on Craft_articles_default_Entry {
          uid
          title
          image0 {
            ...ImageFragment
          }
          url
          postDate
          descriptor0
          articleType {
            ... on Craft_blogType_Category {
              uid
              title
            }
          }
          articleAuthor {
            ... on Craft_authors_default_Entry {
              title
            }
          }
        }
      }
      mediaCoverage: entries(section: "articles", limit: 3, relatedTo: [7797]) {
        ... on Craft_articles_default_Entry {
          uid
          title
          image0 {
            ...ImageFragment
          }
          url
          postDate
          descriptor0
          articleType {
            ... on Craft_blogType_Category {
              uid
              title
            }
          }
          articleAuthor {
            ... on Craft_authors_default_Entry {
              title
            }
          }
        }
      }
      caseStudies: entries(section: "articles", limit: 3, relatedTo: [1175]) {
        ... on Craft_articles_default_Entry {
          uid
          title
          image0 {
            ...ImageFragment
          }
          url
          postDate
          descriptor0
          articleType {
            ... on Craft_blogType_Category {
              uid
              title
            }
          }
          articleAuthor {
            ... on Craft_authors_default_Entry {
              title
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { articles, mediaCoverage, caseStudies } = craft;

  return {
    articles: articles?.map(resolveArticle),
    mediaCoverage: mediaCoverage?.map(resolveArticle),
    caseStudies: caseStudies?.map(resolveArticle),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
