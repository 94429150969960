import React from "react";
import { Text, Icon, Button, ButtonAlt } from "@atoms";
import clsx from "clsx";
import { motion } from "framer-motion";

const AccordionItem = ({
  header,
  copy,
  additionalInfo,
  primaryButton,
  secondaryButton,
  gradientColor,
  i,
  showHeaders,
  state: _state,
}) => {
  const [active, setActive] = _state;
  const open = active === i;
  const buttonColors = {
    pinkToOrangeGradient: "pink-to-orange",
    pinkToSkyBlueGradient: "pink-to-sky-blue",
  };

  return (
    <motion.div
      className="w-full"
      layout
      transition={{ layout: { ease: [0.83, 0, 0.17, 1], duration: 0.2 } }}
    >
      <motion.button
        layout
        type="button"
        aria-label={`Expand accordion containing information on ${header}`}
        className={clsx(
          "border-gradient -mt-0.5 flex w-full justify-between border-2 p-4 text-left",
          {
            "from-orange-gradient to-pink":
              gradientColor === "pinkToOrangeGradient",
            "from-pink to-sky-blue": gradientColor === "pinkToSkyBlueGradient",
            "bg-gradient-to-r": open,
          }
        )}
        onClick={() => setActive(i)}
        transition={{ layout: { ease: [0.83, 0, 0.17, 1], duration: 0.2 } }}
      >
        <Text
          variant="h6"
          className={clsx({
            "text-text-color": !open,
            "text-white": open,
          })}
        >
          {header}
        </Text>
        <Icon
          name="chevron"
          className={clsx("ml-2 h-2.5 w-2.5 transition", {
            "text-white": open,
            "-rotate-90 text-text-color": !open,
          })}
        />
      </motion.button>
      <motion.div
        className="overflow-hidden"
        style={{ height: open ? "auto" : 0 }}
        transition={{ height: { ease: [0.83, 0, 0.17, 1], duration: 0.2 } }}
      >
        <motion.div layout className="p-4 pb-8">
          {showHeaders && (
            <Text variant="h6" className="my-4 text-text-color">
              Answer
            </Text>
          )}
          <Text variant="lg" className="text-text-color">
            {copy}
          </Text>

          {showHeaders && (
            <Text variant="h6" className="my-4 text-text-color">
              Additional Info
            </Text>
          )}

          {additionalInfo?.length > 0 && (
            <ul className="mt-4 space-y-3">
              {additionalInfo?.map((row, _i) => {
                return (
                  <li
                    className="flex w-full max-w-lg flex-nowrap items-baseline"
                    // eslint-disable-next-line react/no-array-index-key
                    key={_i}
                  >
                    <Icon
                      name="bulletArrow"
                      className="mr-2 w-2.5 shrink-0 text-pink"
                    />
                    <span>
                      <Text
                        variant="sm"
                        className="inline font-bold text-text-color"
                      >
                        {`${row?.heading}: `}
                      </Text>
                      <Text
                        variant="sm"
                        className="inline text-text-color opacity-60"
                      >
                        {row?.copy}
                      </Text>
                    </span>
                  </li>
                );
              })}
            </ul>
          )}

          {(primaryButton?.url || secondaryButton?.url) && (
            <div className="mt-4 flex gap-4">
              {primaryButton?.url && (
                <Button
                  color={buttonColors[gradientColor]}
                  to={primaryButton?.url}
                  className="inline"
                >
                  <Text variant="sm">{primaryButton?.text}</Text>
                </Button>
              )}
              {secondaryButton?.url && (
                <ButtonAlt to={secondaryButton?.url} className="inline">
                  <Text variant="sm" className="text-text-color">
                    {secondaryButton?.text}
                  </Text>
                </ButtonAlt>
              )}
            </div>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default AccordionItem;
