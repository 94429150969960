import React from "react";
import loadable from "@loadable/component";

const VideoPlayer = loadable(() => import("../base/VideoPlayer"));
const VideoBackground = loadable(() => import("../base/VideoBackground"));

const Video = ({ url, ...props }) => {
  const isImgur = url?.includes("imgur");

  if (typeof window !== "undefined" && url) {
    return isImgur ? (
      <VideoBackground url={url} {...props} />
    ) : (
      <VideoPlayer url={url} {...props} />
    );
  }

  return <div />;
};

export default Video;
