const reducer = (state, action) => {
  switch (action.type) {
    case "setGradient":
      return {
        ...state,
        bg: action.bg || state.bg,
        main: action.main || state.main,
        highlight: action.highlight || state.highlight,
      };
    default:
      return state;
  }
};

const initialState = {
  bg: "peach",
  main: "pink",
  highlight: "skyBlue",
};

const layout = {
  reducer,
  initialState,
};

export default layout;
