import React from "react";
import { Text, Icon, Container } from "@atoms";
import clsx from "clsx";

const Testimonial = ({ quote, author, title, location }) => {
  return (
    <Container variant="xs">
      <figure
        className={clsx("py-12 sm:px-0", {
          "px-4": author,
          "px-10": !author,
        })}
      >
        {location && (
          <Text className="mx-auto mb-1.5 block text-center font-header text-pink sm:hidden">
            {location}
          </Text>
        )}
        <blockquote className="mb-6 flex flex-row items-start">
          {author && (
            <span className="-mt-2 mr-3 shrink-0" aria-hidden="true">
              <Icon name="quote" className="w-6 text-lavender sm:w-8" />
            </span>
          )}
          <Text
            variant="testimonial"
            className="font-medium leading-tight text-text-color"
          >
            {quote}
          </Text>
        </blockquote>
        <figcaption className="flex justify-end">
          <span>
            {author && (
              <Text variant="body" className="text-text-color">
                {author}
              </Text>
            )}
            {title && (
              <Text variant="sm" className="mt-0.5 text-highlight">
                {title}
              </Text>
            )}
          </span>
        </figcaption>
      </figure>
    </Container>
  );
};

export default Testimonial;
