import React, { useRef, useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import clsx from "clsx";
import useWindowSize from "@hooks/useWindowSize";
import { Icon } from "@atoms";
import tailwindConfig from "@theme";

const Carousel = ({
  children,
  indicators,
  maxVisible = 1,
  className: _className,
  isDark = true,
  insetArrows,
  extraIndicatorSpacing,
  showMax,
  slideState,
  showMobileArrows,
}) => {
  const [currentSlide, setCurrentSlide] = slideState || useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.toArray(children);
  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  const { screens } = tailwindConfig.theme;

  // TODO: pass currentSlide to child component to enable styling when the currentSlide is active

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    const { x: velocity } = info.velocity;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / slideCount || velocity < -400) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / slideCount || velocity > 400) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
        }
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screenNumbers = {};
      Object.keys(screens).map(screen => {
        if (typeof screens[screen] === "string") {
          screenNumbers[screen] = parseInt(
            screens[screen].replace("px", ""),
            10
          );
        }
        return true;
      });
      // configure number of slides based on screen size
      const noSlides = {
        sm: 1,
        md: 1,
        lg: showMax ? maxVisible : 3,
        xl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screenNumbers).find(screen => {
        return width < screenNumbers[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth, visibleSlides]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  return (
    <>
      <div
        ref={carouselContainer}
        className={clsx("relative w-full", _className)}
      >
        {/* prev button */}
        {slideCount > 1 && (
          <div
            className={clsx(
              "translate-y-50 absolute bottom-0 top-0 z-10 transform items-center justify-center",
              {
                "left-8": !insetArrows && !showMobileArrows,
                "left-8 md:-left-8 xl:-left-16": insetArrows,
                " hidden md:flex": !showMobileArrows,
                "left-1.5 flex sm:left-8": showMobileArrows,
              }
            )}
          >
            <button
              className={clsx(
                "group flex items-center justify-center rounded-full border p-4 transition delay-100 duration-300 hover:scale-105",
                {
                  "border border-text-color text-text-color": isDark,
                  "border border-orange text-orange": !isDark,
                  "pointer-events-none opacity-0": currentSlide <= 0,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the previous slide"
            >
              <Icon
                name="arrow"
                className="relative right-0 h-1.5 w-1.5 transform transition duration-300 group-hover:scale-125"
              />
            </button>
          </div>
        )}
        <motion.div
          animate={carouselControls}
          className="flex"
          transition={{ duration: 0.5, type: "tween" }}
          style={{ width: `${slideCount * 100}%` }}
          drag={slideCount > 1 ? "x" : false}
          onDragEnd={handleDrag}
          dragElastic={1}
          dragDirectionLock
        >
          {slides.map((slide, i) => (
            <div
              key={slide.uid || `${i}--${slideCount}`}
              className={clsx("relative duration-300", {
                "opacity-0":
                  i < currentSlide || i + 1 > currentSlide + visibleSlides,
              })}
              style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
            >
              {slide}
            </div>
          ))}
        </motion.div>
        {/* next button */}
        {slideCount > 1 && (
          <div
            className={clsx(
              "translate-y-50 absolute bottom-0 top-0 z-10 transform items-center justify-center",
              {
                "right-8": !insetArrows && !showMobileArrows,
                "right-8 md:-right-8 xl:-right-16": insetArrows,
                "hidden md:flex": !showMobileArrows,
                "right-1.5 flex sm:right-8": showMobileArrows,
              }
            )}
          >
            <button
              className={clsx(
                "group flex items-center justify-center rounded-full border p-4 transition delay-100 duration-300 hover:scale-105",
                {
                  "border-text-color text-text-color": isDark,
                  "border-orange text-orange": !isDark,
                  "pointer-events-none opacity-0":
                    currentSlide >= slideCount - visibleSlides,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the next slide"
            >
              <Icon
                name="arrow"
                className="relative right-0 h-1.5 w-1.5 rotate-180 transition duration-300 group-hover:scale-125"
              />
            </button>
          </div>
        )}
      </div>
      {/* indicators */}
      {indicators && slideCount > 1 && (
        <ul
          className={clsx(
            "relative -top-10 z-10 flex items-center justify-center",
            {
              "mt-16 md:mt-0": extraIndicatorSpacing,
            }
          )}
        >
          {slides.map((slide, i) => (
            <li
              key={
                slide.uid
                  ? `${slide.uid}--button`
                  : `${i}-${slideCount}--button`
              }
            >
              <button
                type="button"
                onClick={() => setCurrentSlide(i)}
                className={clsx(
                  "mx-1 block h-2 w-2 rounded-full bg-text-color transition duration-300",
                  {
                    "opacity-25": currentSlide !== i,
                  }
                )}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Carousel;
