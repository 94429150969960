import React from "react";
import { AppLink } from "@base";
import clsx from "clsx";

const Button = ({
  color = "orange",
  to,
  title,
  type = "button",
  onClick,
  linkObj = AppLink,
  children,
  fullWidth,
  size = "default",
  rounded,
}) => {
  const LinkObj = linkObj;
  const defaultClassName = `tracking-widest rounded-full inline-flex justify-center items-center cursor-pointer items-center relative z-0 group leading-tighter transition duration-200`;

  const sizes = {
    fluid: "p-2 text-xs",
    xxs: "p-2 text-xs",
    xs: "px-5 py-2 text-xxs",
    md: "px-4 py-3 text-xs",
    lg: "px-10 py-5 text-base",
    sm: "py-1.5 px-3 text-sm",
    default: "py-2.5 px-4",
  };

  const colors = {
    orange: "bg-orange hover:bg-peach text-white",
    pink: "bg-pink hover:bg-orange text-white",
    transparent:
      "bg-transparent border border-text-color text-text-color hover:opacity-70",
    whiteTransparent:
      "bg-transparent border border-white text-white hover:opacity-70",
    "pink-to-orange":
      "bg-gradient-to-r from-orange-gradient to-pink text-white",
    "pink-to-sky-blue": "bg-gradient-to-r from-pink to-sky-blue text-white",
    white: "bg-white text-pink hover:text-white hover:bg-black",
  };

  const classes = clsx(
    defaultClassName,
    sizes[size] || "text-sm",
    colors[color] || color,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
    }
  );

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={classes} onClick={onClick}>
        <span className="relative -top-px z-10 flex text-center">
          {children}
        </span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes}
      onClick={onClick}
    >
      <span className="relative -top-px z-10 flex text-center">{children}</span>
    </button>
  );
};

export default Button;
