const unwidower = (content, removeWidows = true) => {
  if (!removeWidows) {
    return content;
  }

  let result;

  const addNbsp = (string = "", unicode = false) => {
    const splitText = string
      ?.trim()
      ?.replace(/&nbsp;/g, " ")
      ?.split(/ (?=[^>]*(?:<|$))/g);

    const secondToLastWord = splitText[splitText.length - 2];
    splitText[splitText.length - 2] = `${secondToLastWord}&nbsp;`;
    return splitText
      .join(" ")
      .replace(/&nbsp; /g, unicode ? "\u00A0" : "&nbsp;");
  };

  const replaceChild = object => {
    const { props } = object;
    const newProps = { ...props, children: addNbsp(props.children) };
    const newObject = { ...object, props: newProps };

    return newObject;
  };

  const findHtmlSections = string => {
    return typeof string === "string"
      ? string
          ?.split(/(<.*?>.*?<.*?>)/g)
          ?.filter(html => html.length > 0)
          ?.map(html => addNbsp(html, false))
          ?.join("")
      : "";
  };

  if (typeof content === "object") {
    if (Array.isArray(content?.props?.children)) {
      result = content?.props?.children?.map(child => replaceChild(child));
    } else {
      result = replaceChild(content);
    }
  } else {
    result = findHtmlSections(content);
  }

  return result;
};

export default unwidower;
