import { useStaticQuery, graphql } from "gatsby";
import { resolveLinks } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          headerLinks {
            ... on Craft_headerLinks_link_BlockType {
              type: typeHandle
              linkObject {
                url
                text
              }
            }
            ... on Craft_headerLinks_button_BlockType {
              type: typeHandle
              linkObject {
                url
                text
              }
            }
            ... on Craft_headerLinks_linkWithADropdown_BlockType {
              type: typeHandle
              linkObject {
                url
                text
              }
              children: dropdown {
                ... on Craft_dropdown_BlockType {
                  subLink {
                    url
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { headerLinks } = craft.globalSet;

  const nav = headerLinks?.map(resolveLinks);

  return { nav };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
