import React, { useEffect, useState } from "react";
import clsx from "clsx";
import theme from "@theme";
import { motion } from "framer-motion";
import { useAppState } from "@state";

const ThemeSetter = ({
  text = "white",
  bg = "black",
  highlight = "lavender",
  sectionChange,
  triggerChange,
  gradientOptions = {
    bg: "peach",
    main: "pink",
    highlight: "skyBlue",
  },
  children,
  rootMargin = "-40px 0px 40px 0px",
  className: _className,
  amount = 0.1,
}) => {
  const setTextColor = newColor => {
    document.documentElement.style.setProperty("--theme-text-color", newColor);
  };
  const setBgColor = newColor => {
    document.documentElement.style.setProperty("--theme-bg-color", newColor);
  };
  const setHighlightColor = newColor => {
    document.documentElement.style.setProperty(
      "--theme-hightlight-color",
      newColor
    );
  };
  const [, dispatch] = useAppState();

  const [prior, setPrior] = useState({
    text: "white",
    bg: "black",
    highlight: "lavender",
  });

  const pageChange = !triggerChange && !sectionChange;

  useEffect(() => {
    if (pageChange) {
      dispatch({
        type: "setGradient",
        ...gradientOptions,
      });
      setTextColor(theme.colors[text].DEFAULT);
      setBgColor(theme.colors[bg].DEFAULT);
      setHighlightColor(theme.colors[highlight].DEFAULT);
    }
  }, []);

  if (!pageChange) {
    return (
      <motion.div
        onViewportEnter={e => {
          const triggerScrollback =
            triggerChange && e?.boundingClientRect.y > 0;

          if (sectionChange || triggerScrollback) {
            setPrior({
              text: document.documentElement.style.getPropertyValue(
                "--theme-text-color"
              ),
              bg: document.documentElement.style.getPropertyValue(
                "--theme-bg-color"
              ),
              highlight: document.documentElement.style.getPropertyValue(
                "--theme-highlight-color"
              ),
            });
          }

          setTextColor(theme.colors[text].DEFAULT);
          setBgColor(theme.colors[bg].DEFAULT);
          setHighlightColor(theme.colors[highlight].DEFAULT);
        }}
        onViewportLeave={e => {
          const triggerScrollback =
            triggerChange && e?.boundingClientRect.y > 0;
          if (sectionChange || triggerScrollback) {
            const { text: t, bg: b, highlight: h } = prior;

            setTextColor(t);
            setBgColor(b);
            setHighlightColor(h);
          }
        }}
        viewport={{ margin: rootMargin, amount }}
        className={clsx(_className)}
      >
        {children}
      </motion.div>
    );
  }

  return children;
};

export default ThemeSetter;
