import React from "react";
import clsx from "clsx";

import { Icon } from "@atoms";
import { AppLink } from "@base";

const Share = ({
  title = "Share:",
  url,
  light,
  color = "pink",
  className: _className,
}) => {
  const urls = {
    LinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`,
    Twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
    Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`,
  };

  const classes = clsx(
    "relative z-10 flex h-6 w-6 items-center justify-center rounded-full p-1.5",
    {
      "bg-purple": color === "purple",
      "bg-orange": color === "orange",
      "bg-pink": color === "pink",
      "bg-gradient-to-t from-pink to-orange-gradient":
        color === "pink-to-orange",
    }
  );

  return (
    <div className={clsx("flex items-center space-x-4", _className)}>
      {title && (
        <span
          className={clsx("font-header font-bold", {
            "text-text-color": light,
            "text-pink ": !light,
          })}
        >
          {title}
        </span>
      )}
      <AppLink to={urls.LinkedIn} target="_blank" className={classes}>
        <Icon name="linkedin" className="h-full w-full text-white" />
      </AppLink>
      <AppLink to={urls.Twitter} target="_blank" className={classes}>
        <Icon name="twitter" className="h-full w-full text-white" />
      </AppLink>
      <AppLink to={urls.Facebook} target="_blank" className={classes}>
        <Icon name="facebook" className="h-full w-2 text-white" />
      </AppLink>
    </div>
  );
};

export default Share;
