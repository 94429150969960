import React from "react";
import clsx from "clsx";

const Container = ({
  children,
  className: _className,
  variant = "md",
  padding,
  interior,
  as = "div",
}) => {
  let classes;
  const px = "px-4 sm:px-8 md:px-10 lg:px-12 xl:px-24";
  // if (flush) {
  //   margin = "-mx-3 sm:-mx-6 md:-mx-8 lg:-mx-12";
  // }
  const Tag = as;
  switch (variant) {
    case "xxs":
      classes = "w-full max-w-xl xl:max-w-2xl mx-auto";
      break;
    case "xs":
      classes = "w-full max-w-3xl xl:max-w-4xl mx-auto";
      break;
    case "sm":
      classes = "w-full max-w-4xl xl:max-w-5xl mx-auto";
      break;
    case "xl":
      classes = "w-full max-w-7xl xl:max-w-8xl mx-auto";
      break;
    default:
      classes = "w-full max-w-5xl xl:max-w-6xl mx-auto";
      break;
  }
  if (padding && !interior) {
    return (
      <Tag
        className="px-0
       sm:px-4 lg:px-12 xl:px-8"
      >
        <div className={clsx(classes, _className, px)}>{children}</div>
      </Tag>
    );
  }
  if (padding && interior) {
    return (
      <Tag className="-px-3 sm:px-0">
        <div className={clsx(classes, _className, px)}>{children}</div>
      </Tag>
    );
  }
  return <Tag className={clsx(classes, _className, px)}>{children}</Tag>;
};

export default Container;
