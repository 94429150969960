import React from "react";
import { Text, Button, ButtonAlt } from "@atoms";
import clsx from "clsx";

const ButtonList = ({
  buttons,
  align,
  textColor,
  className = "mt-6 md:mt-10",
}) => {
  return (
    <div
      className={clsx("flex flex-wrap items-center gap-5", className, {
        "justify-center": !align || align === "center",
        "justify-start": align === "left",
        "justify-end": align === "right",
      })}
    >
      {buttons?.map(button => {
        const { style, url, text } = button;

        if (style === "link") {
          return (
            <ButtonAlt key={button.uid} to={url}>
              <Text
                className={clsx({
                  "text-text-color": !textColor,
                  [textColor]: textColor,
                })}
              >
                {text}
              </Text>
            </ButtonAlt>
          );
        }

        return (
          <Button key={button.uid} to={url} color={style}>
            <Text>{text}</Text>
          </Button>
        );
      })}
    </div>
  );
};

export default ButtonList;
