import React from "react";
import clsx from "clsx";
import { Text } from "@atoms";

const Heading = ({
  heading,
  description,
  label,
  align,
  size = "h2",
  width,
  color,
}) => {
  const headingColor =
    {
      pinkToOrange: "text-l-pink-to-orange",
      blueToPink: "text-r-blue-to-pink",
      pinkToPurple: "text-r-pink-to-purple",
      textColor: "text-text-color",
    }[color] || "text-l-pink-to-orange";
  return (
    <div
      className={clsx("mb-10 w-auto", {
        "text-left": align === "left",
        "text-center": !align,
      })}
    >
      {label && (
        <Text variant="label" className="mb-4 text-text-color">
          {label}
        </Text>
      )}
      <Text variant={size} className={clsx("inline-block", headingColor)}>
        {heading}
      </Text>
      {description && (
        <Text
          variant="lg"
          richText
          className={clsx("mt-4 text-text-color", {
            "ml-0 mr-auto": align === "left",
            "mx-auto": !align,
            "max-w-lg": !width || width === "sm",
            "max-w-1.5xl": width === "lg",
          })}
        >
          {description}
        </Text>
      )}
    </div>
  );
};

export default Heading;
