import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AnnouncementQuery {
    craft {
      globalSet(handle: "announcement") {
        ... on Craft_announcement_GlobalSet {
          boolean0
          heading0
          descriptor0
          link0 {
            text
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { boolean0, heading0, descriptor0, link0 } = craft.globalSet;

  return {
    hasAnnouncement: boolean0,
    header: heading0,
    description: descriptor0,
    link: link0,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
