import React from "react";
import { AppLink } from "@base";
import { Text, Image, ButtonAlt, Button } from "@atoms";
import clsx from "clsx";

const Card = ({
  label,
  image,
  title,
  description,
  author,
  postDate,
  eventDate,
  eventsLocation,
  url,
  center,
  headingSize = "h6",
  hideLinkText,
  horizontal,
  isDark,
  hidePostsData,
  articleType,
  coloredVariant,
  linkText,
  hideDescription,
  showCircles,
  index,
  amount,
}) => {
  const articleText = articleType?.map(c => c.title).includes("Video")
    ? `Watch for More`
    : `Read`;
  const isDarkHover = clsx({
    "text-pink group-hover:text-orange": isDark,
  });
  const showButton = !hideLinkText && url;
  const ButtonTag = coloredVariant ? Button : ButtonAlt;
  const LinkTag = url ? AppLink : "div";

  return (
    <LinkTag
      to={url}
      className={clsx("group w-full", {
        "text-center": center,
        "flex gap-4": horizontal,
      })}
    >
      <div
        className={clsx("overflow-hidden", {
          "w-24 shrink-0": horizontal,
        })}
      >
        {image && (
          <div className="relative w-full">
            <Image
              key={image.uid}
              image={image}
              className={clsx(
                "duration-700 ease-in-out-quad group-hover:scale-105",
                {
                  "aspect-square": horizontal,
                  "aspect-[363/206]": !horizontal && headingSize === "h6",
                  "aspect-[363/400]": !horizontal && headingSize === "h5",
                  "aspect-[500/400]": !horizontal && headingSize === "h4",
                }
              )}
            />
          </div>
        )}
      </div>
      <div
        className={clsx({
          "mt-4 space-y-4": !horizontal,
          "space-y-2": horizontal,
        })}
      >
        {showCircles && (
          <div className="relative flex flex-nowrap items-center justify-between gap-2">
            <div
              className={clsx(
                "overflow-border-l relative w-full border-t border-pink",
                {
                  "md:overflow-border-l-full": index === 0 || (index + 1) % 2,
                }
              )}
            />
            <div className="double-border relative m-4 mx-auto size-4 shrink-0 rounded-full border border-pink" />
            <div
              className={clsx(
                "overflow-border-r relative w-full border-t border-pink",
                {
                  "md:overflow-border-r-full":
                    index === amount - 1 || !((index + 1) % 2),
                }
              )}
            />
          </div>
        )}
        <Text variant="label" className="text-text-color">
          {label}
        </Text>
        <Text
          variant={showCircles ? "h5" : headingSize}
          className={clsx("inline-block", {
            // "text-highlight": isDark,
            "text-pink": !coloredVariant,
            "text-l-pink-to-orange": coloredVariant,
          })}
        >
          {title}
        </Text>
        {author && postDate && !hidePostsData && (
          <Text
            variant="body"
            className={clsx({
              "text-text-color": isDark,
              "text-black text-opacity-50 group-hover:text-opacity-100":
                !isDark,
            })}
          >
            <span>
              <span className={!isDarkHover}>by</span> {author}{" "}
              <span className={!isDarkHover}>on</span> {postDate}
            </span>
          </Text>
        )}
        {eventDate && !eventsLocation && (
          <Text variant="body" className="text-text-color">
            <span>
              <span className={isDarkHover}>on</span> {eventDate}
            </span>
          </Text>
        )}
        {eventDate && eventsLocation && (
          <Text
            variant="body"
            className={clsx({
              "text-text-color": isDark,
              "text-black text-opacity-50 group-hover:text-opacity-100":
                !isDark,
            })}
          >
            <span>
              <span className={isDarkHover}>Date</span> {eventDate}{" "}
              <span className={isDarkHover}>Location</span> {eventsLocation}
            </span>
          </Text>
        )}
        {!hideDescription && description && (
          <Text
            variant={horizontal ? "sm" : "lg"}
            className={clsx("prose my-4 text-text-color", {
              "line-clamp-2": horizontal,
              "text-left": showCircles,
              "text-center": !image && !horizontal,
            })}
          >
            {description}
          </Text>
        )}
        {showButton && (
          <ButtonTag
            color="pink"
            className={clsx("block w-full", {
              "flex justify-center": center,
            })}
          >
            <Text
              className={clsx({
                "text-text-color": !coloredVariant,
                "text-white": coloredVariant,
              })}
            >
              {postDate && author ? articleText : linkText || `Learn More`}
            </Text>
          </ButtonTag>
        )}
      </div>
    </LinkTag>
  );
};

export default Card;
