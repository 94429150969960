import React from "react";
import { Text, ButtonAlt } from "@atoms";

const CbCta = ({ heading, description, link }) => {
  return (
    <div className="my-6 md:my-10">
      <div className="space-y-4 bg-gradient-to-t from-pink to-orange-gradient p-8 text-center">
        <Text variant="h5" className="text-white">
          {heading}
        </Text>
        <Text variant="body" className="text-white">
          {description}
        </Text>
        {link?.url && (
          <ButtonAlt
            to={link?.url}
            className="!decoration-white hover:!decoration-orange"
          >
            <Text variant="body" className="text-white">
              {link?.text}
            </Text>
          </ButtonAlt>
        )}
      </div>
    </div>
  );
};

export default CbCta;
