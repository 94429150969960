import resolveImage from "./resolveImage";

const resolveEvent = article => {
  const date = Date.parse(article?.eventDate);
  const eventDate = new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const month = new Date(0, new Date(date).getMonth()).toLocaleString("en", {
    month: "long",
  });
  const isCurrentMonth = new Date(date).getMonth() === new Date().getMonth();

  return {
    ...article,
    image: resolveImage(article?.image0),
    eventDate,
    month,
    isCurrentMonth,
  };
};

export default resolveEvent;
