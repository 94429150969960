import React, { useState } from "react";
import { Text, Button, Icon } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";

const HeaderDropdown = ({ text, url, children }) => {
  const [visible, setVisible] = useState(false);
  const variants = {
    open: { height: "auto" },
    closed: { height: 0 },
  };

  return (
    <li className="relative">
      <AppLink
        className={clsx("relative z-20 py-2 text-base font-bold", {
          "inline-block": children?.length > 0,
          block: !children?.length,
        })}
        to={url}
      >
        <Text variant="nav" className="justify-end !text-black">
          {text}
        </Text>
      </AppLink>
      {children?.length > 0 && (
        <button
          type="button"
          className="absolute top-0 z-10 flex h-full w-full justify-end text-black"
          onClick={() => setVisible(!visible)}
          aria-label={`Open ${text} dropdown`}
        >
          <Icon
            name="chevron"
            className={clsx("ml-2 h-2.5 w-2.5 text-pink transition", {
              "-rotate-90": visible,
            })}
          />
        </button>
      )}
      {children?.length > 0 && (
        <motion.ul
          className="relative z-10 space-y-3 overflow-hidden"
          animate={visible ? "open" : "closed"}
          initial="closed"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          {children?.map((subLink, _i) => {
            const { text: _text, url: _url } = subLink;
            return (
              <li key={`mobile-sublink-${_text}--${_i + 1}`} className="">
                <AppLink to={_url} className="ml-4 text-sm font-bold">
                  <Text className="text-black">{_text}</Text>
                </AppLink>
              </li>
            );
          })}
        </motion.ul>
      )}
    </li>
  );
};

const MobileHeader = ({ nav }) => {
  const [{ layout }] = useAppState();

  // Mobile nav triggers
  const { hideNav } = layout;

  if (nav) {
    return (
      <nav
        aria-label="Mobile navigation"
        aria-live={!hideNav ? "polite" : "off"}
        className="absolute left-0 right-0 z-50 w-full p-4 md:hidden"
      >
        <AnimatePresence>
          {!hideNav && (
            <motion.ul
              className={clsx(
                "h-full w-full space-y-2 rounded-md bg-white px-4 py-4 shadow-xl",
                {
                  "pointer-events-none": hideNav,
                }
              )}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {nav?.map((link, i) => {
                const { text, url, button } = link;

                if (button) {
                  return (
                    <li key={`mobile-link-${text}--${i + 1}`}>
                      <Button to={url} size="sm">
                        <Text>{text}</Text>
                      </Button>
                    </li>
                  );
                }

                return (
                  <HeaderDropdown
                    key={`mobile-link-${text}--${i + 1}`}
                    {...link}
                  />
                );
              })}
            </motion.ul>
          )}
        </AnimatePresence>
      </nav>
    );
  }
  return null;
};

export default MobileHeader;
