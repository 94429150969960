const resolveLinks = col => {
  const { type, linkObject } = col;

  if (type === "button") {
    return {
      ...linkObject,
      button: true,
    };
  }

  if (type === "linkWithADropdown") {
    return {
      ...linkObject,
      children: col?.children?.map(link => link.subLink),
    };
  }

  if (type === "linkList") {
    return col?.links?.map(link => link?.linkObject);
  }

  return linkObject;
};

export default resolveLinks;
