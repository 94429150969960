import React, { useState, useEffect, useMemo } from "react";
import { Input, Fieldset, Text } from "@atoms";
import clsx from "clsx";
import FormContainer from "./FormContainer";

const Form = ({ heading: _h, formEl, html: _html }) => {
  const [form, setForm] = useState({});

  const formTitle = _html?.querySelector(
    `[data-testid="form-title"]`
  )?.innerText;
  // const formDesc = (formEl || document).querySelector(`.htmlContent`)[0]
  //   ?.innerText;

  const formEls = Array.from(formEl?.elements || []);
  const inFieldsets = formEls
    ?.map(input => {
      const { type } = input;

      if (type === "fieldset") {
        return Array.from(input?.elements)?.map(_i => _i?.id);
      }
      return null;
    })
    .flat()
    .filter(thing => thing);
  const formElements = formEls.filter(element => {
    return !inFieldsets.includes(element?.id);
  });

  useEffect(() => {
    formEls?.forEach(input => {
      const { type, value, name } = input;
      // since formassembly needs some hidden fields
      // throw them up
      if (type === "hidden") {
        setForm(prior => ({ ...prior, [name]: value }));
      }
    });
  }, []);

  const submitForm = async (e, action) => {
    e.preventDefault();
    try {
      // Make request to submit form with updated formData
      // set the url so that the api can get it

      const data = await fetch("/api/formassembly", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ ...form, url: action }),
      });

      return data.json();
    } catch (error) {
      return error;
    }
  };

  const parseInputs = (input, i) => {
    const { id, type, title, name, value } = input || {};
    const label =
      id && (type !== "submit" || type !== "hidden")
        ? formEl?.querySelector(`[for="${id}"]`)?.innerHTML
        : title;

    const required = input?.attributes?.["aria-required"]?.value === "true";
    const options = input?.options
      ? Array.from(input?.options)?.map(option => ({
          text: option?.innerHTML,
          value: option?.value,
        }))
      : null;

    if (type === "submit" || id?.includes("close-button")) {
      return null;
    }
    if (type === "fieldset") {
      return (
        <Fieldset className="grid gap-4 sm:grid-cols-2">
          {Array.from(input?.elements)?.map(parseInputs)}
        </Fieldset>
      );
    }
    return (
      <Input
        id={id}
        label={label}
        key={id}
        type={type === "select-one" ? "select" : type}
        options={options}
        name={name}
        value={type === "hidden" ? value : null}
        required={required}
        className={clsx({
          // have the top select centered
          "flex justify-center": type === "select-one" && i === 0,
        })}
      />
    );
  };

  // weird inconsistent issues were happening using the dompaser
  // so appending the last input fixed it
  const inputEls = useMemo(() => formElements?.map(parseInputs), []);

  return (
    <div className="bg-gradient-to-t from-pink to-orange-gradient p-6 px-8">
      <Text variant="h5" className="mb-4 text-center text-white">
        {formTitle || _h}
      </Text>
      {/*      {(formDesc || _d) && (
        <Text variant="body" className="text-white -mt-2 mb-8 text-center">
          {formDesc || _d}
        </Text>
      )} */}

      <FormContainer
        buttonColor="whiteTransparent"
        onSubmit={e => submitForm(e, formEl?.action)}
        formState={[form, setForm]}
        className="space-y-4"
      >
        {inputEls}
      </FormContainer>
    </div>
  );
};

export default Form;
