import React from "react";
import { Text } from "@atoms";

const CbLead = ({ copy }) => {
  return (
    <div className="my-6 md:my-10">
      <Text variant="xl">{copy}</Text>
    </div>
  );
};

export default CbLead;
