const resolveNewsletterContent = (section, i) => {
  const alternatingColors = i % 2 !== 0 ? "text-pink" : "text-orange";
  return {
    ...section,
    headingSize: i === 0 ? "h2" : "h3",
    headingColor: i === 0 ? "text-l-pink-to-orange" : alternatingColors,
    copySize: i === 0 ? "xl" : "lg",
  };
};

export default resolveNewsletterContent;
