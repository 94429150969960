import layout from "./reducers/layout";
import modal from "./reducers/modal";
import gradient from "./reducers/gradient";
import createAppState from "./lib/createAppState";

const reducer = {
  layout: layout.reducer,
  modal: modal.reducer,
  gradient: gradient.reducer,
};

const initialState = {
  layout: layout.initialState,
  modal: modal.initialState,
  gradient: gradient.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
