import React from "react";
import { Helmet } from "react-helmet";
import { PageSizeObserver } from "@base";
import { Modal } from "@molecules";
import loadable from "@loadable/component";

import BrowserWarning from "../atoms/BrowserWarning";
import lightIcon from "../../../static/images/icon.png";

const Header = loadable(() => import("@organisms/Header"));
const Footer = loadable(() => import("@organisms/Footer"));
const BackgroundGradient = loadable(() => import("./BackgroundGradient"));
const Announcement = loadable(() => import("@organisms/Announcement"));
const GdprBanner = loadable(() => import("../organisms/GdprBanner"));
const Userback = process.env.GATSBY_USERBACK
  ? loadable(() => import("@organisms/Userback"))
  : null;

const DefaultLayout = ({ children, context }) => {
  const { hideFooter } = context || {};
  // the layout
  return (
    <div id="app_layout">
      <PageSizeObserver />
      <Helmet>
        <html lang="en" />
        {/* set icon variant based on browser theme */}
        <link
          rel="icon"
          type="image/png"
          sizes="16x16 32x32"
          href={lightIcon}
        />
        {/* uncomment to load typekit */}
        {/* <link rel="preload" href={process.env.GATSBY_TYPEKIT} as="style" />
        <link rel="stylesheet" href={process.env.GATSBY_TYPEKIT} /> */}
        {/* uncomment to allow social embeds */}
        {/* <script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&autoLogAppEvents=1&version=v7.0&appId=${process.env.GATSBY_FACEBOOK_APP_ID}`}
          nonce="12UgffVI"
        />
        <script
          async=""
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        />
        <script async src="https://www.instagram.com/embed.js" /> */}

        {/* Using Fathom as first party tracking */}
        {/* Setting data-spa should handle page view changing */}
        {/* If more granular events tracking is needed: https://usefathom.com/docs/features/events */}
        {process.env.GATSBY_FATHOM_SITEID && (
          <script
            src="https://cdn.usefathom.com/script.js"
            data-site={process.env.GATSBY_FATHOM_SITEID}
            data-spa="auto"
            defer
          />
        )}
      </Helmet>
      <Announcement />
      <Header />
      {children}
      {!hideFooter && <Footer />}
      {process.env.GATSBY_USERBACK && <Userback />}
      <BackgroundGradient />
      <BrowserWarning />
      <Modal />
      <GdprBanner />
      {/* uncomment if embedding facebook posts */}
      {/* <div id="fb-root" /> */}
    </div>
  );
};

export default DefaultLayout;
