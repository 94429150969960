import { useStaticQuery, graphql } from "gatsby";
import { resolveNewsletterContent } from "@dataResolvers";

const query = graphql`
  query NewsletterQuery {
    craft {
      globalSet(handle: "newsletter") {
        ... on Craft_newsletter_GlobalSet {
          # Copy
          newsletterCopy {
            ... on Craft_newsletterCopy_newsletterCopySection_BlockType {
              heading
              copy
              button {
                url
                text
              }
            }
          }
          # Form
          heading0
          descriptor0
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const { newsletterCopy, heading0, descriptor0 } = craft.globalSet;

  const content = newsletterCopy?.map(resolveNewsletterContent);
  const form = {
    heading: heading0,
    description: descriptor0,
  };

  return { content, form };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
